// styles for mat-select
// to apply these styles, wrap the mat-form-field inside a div with 'custom-mat-select' class

.custom-mat-select {
  .mat-mdc-form-field {
    width: 240px;
  }

  .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled)
    .mat-mdc-text-field-wrapper {
    height: 36px;
    border-radius: 4px;
    background-color: white;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mat-mdc-notch-piece {
    border-width: 2px;
  }
}
